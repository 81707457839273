import React from 'react';
import { connect } from 'react-redux';
import FieldsContainer from './FieldsContainer.js';
import NumericField from './NumericField.js';
import formDataActions from '../actions/formDataActions.js';

function HeartRateTabFields(props)
{
	const { heartRate } = props;

	const onUpdateValue = (sender,e) => {
		const property = sender.substr(sender.indexOf('_')+1);
		const value    = e.floatValue

		props.dispatch(formDataActions.setHeartRateValue(property, value));
	};

	return (
		<FieldsContainer>
			<div>
				<label htmlFor="heartrate_max">HR max</label>
				<NumericField
					id="heartrate_max"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={heartRate.max}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_maxWeight">HR max weight</label>
				<NumericField
					id="heartrate_maxWeight"
					onUpdateValue={onUpdateValue}
					value={heartRate.maxWeight}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_anaerobic">HR anaerobic</label>
				<NumericField
					id="heartrate_anaerobic"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={heartRate.anaerobic}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_anaerobicWeight">HR anaerobic weight</label>
				<NumericField
					id="heartrate_anaerobicWeight"
					onUpdateValue={onUpdateValue}
					value={heartRate.anaerobicWeight}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_aerobic">HR aerobic</label>
				<NumericField
					id="heartrate_aerobic"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={heartRate.aerobic}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_aerobicWeight">HR aerobic</label>
				<NumericField
					id="heartrate_aerobicWeight"
					onUpdateValue={onUpdateValue}
					value={heartRate.aerobicWeight}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_recovery">HR recovery</label>
				<NumericField
					id="heartrate_recovery"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={heartRate.recovery}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_recoveryWeight">HR recovery</label>
				<NumericField
					id="heartrate_recoveryWeight"
					onUpdateValue={onUpdateValue}
					value={heartRate.recoveryWeight}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_lowerLimit">HR lower limit</label>
				<NumericField
					id="heartrate_lowerLimit"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={heartRate.lowerLimit}
					/>
			</div>
			<div>
				<label htmlFor="heartrate_lowerLimitWeight">HR lower limit</label>
				<NumericField
					id="heartrate_lowerLimitWeight"
					onUpdateValue={onUpdateValue}
					value={heartRate.lowerLimitWeight}
					/>
			</div>
		</FieldsContainer>
	);
}

const mapStateToProps = state => {
	return {
		heartRate: state.formData.heartRate
	};
}

const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeartRateTabFields)
