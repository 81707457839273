import formUploadConstants from '../constants/formUploadConstants.js';
import trimpService from '../services/trimpService.js';

function submitForm(zipFile, parameters)
{
	return (dispatch) => {
		dispatch({
			type: formUploadConstants.SUBMIT_FORM,
			zipFile,
			parameters
		});

		trimpService.uploadData(zipFile, parameters)
			.then(result =>
			{
				dispatch({
					type: formUploadConstants.SUBMIT_FORM_SUCCESS,
					zipFile,
					parameters,
					result
				});
			},
			error =>
			{
				dispatch({
					type: formUploadConstants.SUBMIT_FORM_FAILURE,
					zipFile,
					parameters,
					error
				});
			});
	}
};

function resetSubmitForm()
{
	return (dispatch) => {
		dispatch({
			type: formUploadConstants.RESET_SUBMIT_FORM,
		});
	}
};

export default {
	submitForm,
	resetSubmitForm,
};
