import formDataConstants from '../constants/formDataConstants.js';

function setUploadFile(file)
{
	return (dispatch) => {
		dispatch({
			type: formDataConstants.SET_UPLOAD_FILE,
			file
		});
	}
};

function resetUploadFile()
{
	return (dispatch) => {
		dispatch({
			type: formDataConstants.RESET_UPLOAD_FILE,
		});
	}
};

function setHeartRateValue(property, value)
{
	return (dispatch) => {
		dispatch({
			type: formDataConstants.SET_HEARTRATE_VALUE,
			property,
			value
		});
	}
};

function setPowerValue(property, value)
{
	return (dispatch) => {
		dispatch({
			type: formDataConstants.SET_POWER_VALUE,
			property,
			value
		});
	}
};

function setSpeedValue(property, value)
{
	return (dispatch) => {
		dispatch({
			type: formDataConstants.SET_SPEED_VALUE,
			property,
			value
		});
	}
};

export default {
	setUploadFile,
	resetUploadFile,
	setHeartRateValue,
	setPowerValue,
	setSpeedValue,
};
