import React from 'react';
import { connect } from 'react-redux';
import FieldsContainer from './FieldsContainer.js';
import NumericField from './NumericField.js';
import formDataActions from '../actions/formDataActions.js';

function PowerTabFields(props)
{
	const { power } = props;

	const onUpdateValue = (sender,e) => {
		const property = sender.substr(sender.indexOf('_')+1);
		const value    = e.floatValue

		props.dispatch(formDataActions.setPowerValue(property, value));
	};

	return (
		<FieldsContainer>
			<div>

			</div>
			<div>
				<label htmlFor="power_maxWeight">Power max weight</label>
				<NumericField
					id="power_maxWeight"
					onUpdateValue={onUpdateValue}
					value={power.maxWeight}
					/>
			</div>
			<div>
				<label htmlFor="power_vo2Max">Power VO2 Max</label>
				<NumericField
					id="power_vo2Max"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={power.vo2Max}
					/>
			</div>
			<div>
				<label htmlFor="power_vo2MaxWeight">Power VO2 Max weight</label>
				<NumericField
					id="power_vo2MaxWeight"
					onUpdateValue={onUpdateValue}
					value={power.vo2MaxWeight}
					/>
			</div>
			<div>
				<label htmlFor="power_anaerobic">Power anaerobic</label>
				<NumericField
					id="power_anaerobic"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={power.anaerobic}
					/>
			</div>
			<div>
				<label htmlFor="power_anaerobicWeight">Power anaerobic weight</label>
				<NumericField
					id="power_anaerobicWeight"
					onUpdateValue={onUpdateValue}
					value={power.anaerobicWeight}
					/>
			</div>
			<div>
				<label htmlFor="power_aerobic">Power aerobic</label>
				<NumericField
					id="power_aerobic"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={power.aerobic}
					/>
			</div>
			<div>
				<label htmlFor="power_aerobicWeight">Power aerobic</label>
				<NumericField
					id="power_aerobicWeight"
					onUpdateValue={onUpdateValue}
					value={power.aerobicWeight}
					/>
			</div>
			<div>
				<label htmlFor="power_recovery">Power recovery</label>
				<NumericField
					id="power_recovery"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={power.recovery}
					/>
			</div>
			<div>
				<label htmlFor="power_recoveryWeight">Power recovery</label>
				<NumericField
					id="power_recoveryWeight"
					onUpdateValue={onUpdateValue}
					value={power.recoveryWeight}
					/>
			</div>
			<div>
				<label htmlFor="power_lowerLimit">Power lower limit</label>
				<NumericField
					id="power_lowerLimit"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={power.lowerLimit}
					/>
			</div>
			<div>
				<label htmlFor="power_lowerLimitWeight">Power lower limit</label>
				<NumericField
					id="power_lowerLimitWeight"
					onUpdateValue={onUpdateValue}
					value={power.lowerLimitWeight}
					/>
			</div>
		</FieldsContainer>
	);
}

const mapStateToProps = state => {
	return {
		power: state.formData.power
	};
}

const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PowerTabFields)
