import React from 'react';
import { createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router, Route } from "react-router-dom";
import IndexPage from './pages/IndexPage.js';

const GlobalStyle = createGlobalStyle`
	body {
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
		font-size: .9em;
		background-color: #f9f9f9;
		color: #333;
	}
`;

export default function App() {
	return (
		<Router>
			<GlobalStyle />
			<Route match="*" component={IndexPage} />
		</Router>
	);
};
