import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
`

export default function Wrapper(props)
{
	return (
		<Div>{props.children}</Div>
	);
}
