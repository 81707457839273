import React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

import logo from '../assets/img/coachbox-logo-mail@2x.png';
import formUploadActions from '../actions/formUploadActions.js';
import BodyPanel from '../components/BodyPanel.js';
import UploadField from '../components/UploadField.js';
import HeartRateTabFields from '../components/HeartRateTabFields.js';
import PowerTabFields from '../components/PowerTabFields.js';
import SpeedTabFields from '../components/SpeedTabFields.js';
import Wrapper from '../components/Wrapper.js';

const Title = styled.h1`
	width: 209px;
	height: 30px;
	background-image: url(${logo});
	background-size: 209px 30px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	text-indent: -9999px;
	margin-left: auto;
	margin-right: auto;
`;

const CBTabList = styled(TabList)`
	list-style: none;
	margin: 0 -15px;
	padding: 0;
	border-bottom: 1px solid #f1f1f1;
`;

const CBTab = styled(Tab)`
	display: inline-block;
	position: relative;
	border-radius: 3px 3px 0 0;
	border: 1px solid #f1f1f1;
	background: #efefef;
	text-align: middle;
	color: #aaa;
	margin: 0 10px -1px 0;
	padding: 10px 15px;
	z-index: 2;

	> svg {
		fill: #ccc;
	}

	&:first-child {
		margin-left: 15px
	}

	&:first-child {
		margin-left: 15px
	}

	&[aria-selected="true"] {
		border-bottom: 1px solid #fff;
		background: #fff;
		color: #333;

		> svg {
			fill: #333;
		}
	}

	&[aria-selected="false"] {
		cursor: pointer;
	}

	&[aria-selected="false"]:hover {
		background: #f9f9f9;
	}
`;

const SvgTabIcon = styled.svg`
	display: inline-block;
	float: left;
	width: 14px;
	height: 14px;
	margin: 0 5px 0 0;
	padding: 0;
`;

const CBTabPanel = styled(TabPanel)`
	display: none;
	padding: 15px 0 0;

	&.react-tabs__tab-panel--selected {
		display: block;
	}
`;

const Footer = styled.footer`
	text-align: right;
	vertical-align: middle;
`;

const ErrorMessage = styled.div`
	border-top: 1px solid #eee
	color: #c30;
	font-size: 12px;
	font-weight: 600;
	text-align: center;
	margin-top: 15px;
	padding-top: 15px;

	a {
		color: #c30;
	}
`;

const LoadingSvg = styled.svg`
	display: inline-block;
	position: relative;
	top: 6px;
	width: 24px;
	height: 24px;
	margin-right: 15px;
`;

const SubmitButton = styled.input`
	-webkit-appearance: none;
	cursor: pointer;
	border: none;
	border-radius: 2px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
	color: #fff;
	background-color: #A4D55C;
	padding: 10px 15px;

	&:disabled {
		background-color: #CFCFCF;
		cursor: default;
	}
	&:not(:disabled):hover {
		background-color: #AEDE67;
	}
	&:focus {
	}
	&:active {
	}
`;

class IndexPage extends React.Component
{
	componentDidUpdate(prevProps, prevState, snapshot)
	{
		const { props } = this;

		if (prevProps.formUpload !== props.formUpload)
		{
			const { formUpload } = props;

			if (formUpload.loading === false &&
				formUpload.loaded !== null &&
				formUpload.result !== null)
			{
				window.open(formUpload.result.redirect);
			}
		}
	}

	onClickButton = (e) => {
		const { dispatch, uploadFile, heartRate, power, speed } = this.props;
		e.preventDefault();

		dispatch(formUploadActions.submitForm(uploadFile, {
			heartrate: heartRate,
			power: power,
			speed: speed,
		}));
	};

	render()
	{
		const { formUpload, uploadFile, heartRate, power, speed } = this.props;

		const heartRateValues = Object.keys(heartRate).map((key) => (heartRate[key]));
		const powerValues = Object.keys(power).map((key) => (power[key]));
		const speedValues = Object.keys(speed).map((key) => (speed[key]));

		const uploadButtonDisabled = (formUpload.loading === true ||
			uploadFile === null ||
			heartRateValues.filter((value) => value === undefined).length > 0 ||
			powerValues.filter((value) => value === undefined).length > 0 ||
			speedValues.filter((value) => value === undefined).length > 0);

		return (
			<Wrapper>
				<Title>Coachbox Trimp</Title>
				<BodyPanel>
					<UploadField />
					<Tabs>
						<CBTabList>
							<CBTab>
								<SvgTabIcon x="0px" y="0px" viewBox="0 0 20 20">
									<path d="M16.6,4.3c-1.8-1.8-4.8-1.8-6.6,0c-1.8-1.8-4.8-1.8-6.6,0c-1.8,1.8-1.8,4.6,0,6.3L3.7,11H4h1.2H7c0.2,0,0.4-0.2,0.5-0.4 L8.1,8l1.5,5.1c0.1,0.2,0.2,0.4,0.5,0.4c0,0,0,0,0,0c0.2,0,0.4-0.1,0.5-0.3l1.2-3.1l0.5,0.7c0.1,0.1,0.2,0.2,0.4,0.2h2.3H16h0.3 l0.4-0.3C18.5,8.9,18.5,6.1,16.6,4.3z M15.9,9.9L15.9,9.9L12.8,10l-0.9-1.3c-0.1-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.3l-1,2.6 L8.5,5.9C8.4,5.6,8.2,5.5,8,5.5c-0.2,0-0.4,0.2-0.5,0.4L6.6,10H4.1L4.1,9.9C3.4,9.3,3,8.4,3,7.5C3,6.6,3.4,5.7,4.1,5 c0.7-0.7,1.6-1,2.6-1c1,0,1.9,0.4,2.6,1L10,5.7L10.7,5c0.7-0.7,1.6-1,2.6-1s1.9,0.4,2.6,1C16.6,5.7,17,6.6,17,7.5S16.6,9.3,15.9,9.9 z M13.8,12h1.4l-4.1,3.9L10,17l-1.1-1.1L4.8,12h1.4l3.8,3.6L13.8,12z"></path>
								</SvgTabIcon>
								Heartrate
							</CBTab>
							<CBTab>
								<SvgTabIcon x="0px" y="0px" viewBox="0 0 20 20">
									<path d="M10,8.1c-0.8,0-1.5-0.7-1.5-1.5S9.2,5,10,5s1.5,0.7,1.5,1.5S10.8,8.1,10,8.1z M14.7,4.9h-1.2c0,0-1.2,3.8-3.5,3.8 S6.5,4.9,6.5,4.9H5.3c0.2,1.2,0.4,2.2,1,3.3c0.6,1.2,1.8,2.2,1.8,3.1c0,0.7-0.4,2-0.4,2l-2,5.6h1.7c0,0,1.8-5,2.5-5s2.5,5,2.5,5h1.7 l-2-5.6c0,0-0.4-1.3-0.4-2c0-0.9,1.2-2,1.8-3.1C14.2,7.1,14.4,6.2,14.7,4.9z M3.2,1c-0.5,0-1,0.4-1,1v1.2H1.6 c-0.3,0-0.6,0.2-0.6,0.6s0.2,0.6,0.6,0.6h0.6v1.2c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1V4.3V3.2V2C4.2,1.4,3.8,1,3.2,1z M16.8,1 c-0.5,0-1,0.4-1,1v1.2H4.7v1.1h11.1v1.2c0,0.5,0.4,1,1,1s1-0.4,1-1V4.3V3.2V2C17.7,1.4,17.3,1,16.8,1z M18.4,3.2h-0.1v1.1h0.1 c0.3,0,0.6-0.2,0.6-0.6S18.7,3.2,18.4,3.2z"></path>
								</SvgTabIcon>
								Power
							</CBTab>
							<CBTab>
								<SvgTabIcon x="0px" y="0px" viewBox="0 0 20 20">
									<path d="M10,4c-4.4,0-8,3.6-8,8c0,1.5,0.4,2.8,1.1,4h0.4h0.8h11.5h0.8h0.4c0.7-1.2,1.1-2.5,1.1-4C18,7.6,14.4,4,10,4z M16.3,15H3.7 C3.3,14.1,3,13.1,3,12c0-3.9,3.1-7,7-7s7,3.1,7,7C17,13.1,16.7,14.1,16.3,15z M13.6,7.7l-2.1,3.7c0.9,0.5,1.5,1.5,1.5,2.6H7 c0-1.7,1.3-3,3-3c0.2,0,0.4,0,0.5,0.1l2.2-3.8c0.1-0.2,0.4-0.3,0.7-0.2C13.7,7.2,13.8,7.5,13.6,7.7z"></path>
								</SvgTabIcon>
								Speed
							</CBTab>
						</CBTabList>
						<CBTabPanel>
							<HeartRateTabFields />
						</CBTabPanel>
						<CBTabPanel>
							<PowerTabFields />
						</CBTabPanel>
						<CBTabPanel>
							<SpeedTabFields />
						</CBTabPanel>
					</Tabs>
					<Footer>
						{formUpload.loading === true && (
							<LoadingSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{ background: 'none' }}>
								<circle cx="50" cy="50" ng-attr-r="40" ng-attr-stroke-width="15" ng-attr-stroke="0" ng-attr-stroke-dasharray="0" fill="none" strokeLinecap="round" r="40" strokeWidth="15" stroke="#A4D55C" strokeDasharray="70.68583470577035 70.68583470577035" transform="rotate(15.6394 50 50)">
									<animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.5s" begin="0s" repeatCount="indefinite"></animateTransform>
								</circle>
							</LoadingSvg>
						)}
						<SubmitButton
							disabled={uploadButtonDisabled}
							onClick={this.onClickButton}
							type="submit"
							value="Upload"
							/>
						{formUpload.loading === false &&
						formUpload.loaded !== null &&
						formUpload.result === null && (
							<ErrorMessage>
								Something went wrong, please double-check your input parameters and try again...  <br/>
								If this keeps happening, please contact <a href="mailto:support@coachbox.app">support@coachbox.app</a>
							</ErrorMessage>
						)}
					</Footer>
				</BodyPanel>
			</Wrapper>
		);
	}
}

const mapStateToProps = state => {
	return {
		formUpload: state.formUpload,
		uploadFile: state.formData.uploadFile,
		heartRate: state.formData.heartRate,
		power: state.formData.power,
		speed: state.formData.speed,
	};
}

const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexPage)
