import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
	background: #fff;
	border: 1px solid #f1f1f1;
	border-radius: 2px;
	padding: 15px;
`;

export default function BodyPanel(props)
{
	return (
		<Form action="">{props.children}</Form>
	);
}
