import React from 'react';
import NumberFormat from 'react-number-format';

export default function FieldsContainer(props)
{
	const onValueChange = (e) => {
		props.onUpdateValue(props.id, e);
	};

	return (
		<NumberFormat
			style={{
				backgroundColor: props.value === undefined ? '#FFF5F5' : undefined
			}}
			thousandSeparator=""
			decimalScale={3}
			allowEmptyFormatting={false}
			onValueChange={onValueChange}
			{...props}
			/>
	);
}
