import React from 'react';
import { connect } from 'react-redux';
import FieldsContainer from './FieldsContainer.js';
import NumericField from './NumericField.js';
import formDataActions from '../actions/formDataActions.js';

function SpeedTabFields(props)
{
	const { speed } = props;

	const onUpdateValue = (sender,e) => {
		const property = sender.substr(sender.indexOf('_')+1);
		const value    = e.floatValue

		props.dispatch(formDataActions.setSpeedValue(property, value));
	};

	return (
		<FieldsContainer>
			<div>

			</div>
			<div>
				<label htmlFor="speed_maxWeight">Speed max weight</label>
				<NumericField
					id="speed_maxWeight"
					onUpdateValue={onUpdateValue}
					value={speed.maxWeight}
					/>
			</div>
			<div>
				<label htmlFor="speed_vo2Max">Speed VO2 Max</label>
				<NumericField
					id="speed_vo2Max"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={speed.vo2Max}
					/>
			</div>
			<div>
				<label htmlFor="speed_vo2MaxWeight">Speed VO2 Max weight</label>
				<NumericField
					id="speed_vo2MaxWeight"
					onUpdateValue={onUpdateValue}
					value={speed.vo2MaxWeight}
					/>
			</div>
			<div>
				<label htmlFor="speed_anaerobic">Speed anaerobic</label>
				<NumericField
					id="speed_anaerobic"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={speed.anaerobic}
					/>
			</div>
			<div>
				<label htmlFor="speed_anaerobicWeight">Speed anaerobic weight</label>
				<NumericField
					id="speed_anaerobicWeight"
					onUpdateValue={onUpdateValue}
					value={speed.anaerobicWeight}
					/>
			</div>
			<div>
				<label htmlFor="speed_aerobic">Speed aerobic</label>
				<NumericField
					id="speed_aerobic"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={speed.aerobic}
					/>
			</div>
			<div>
				<label htmlFor="speed_aerobicWeight">Speed aerobic</label>
				<NumericField
					id="speed_aerobicWeight"
					onUpdateValue={onUpdateValue}
					value={speed.aerobicWeight}
					/>
			</div>
			<div>
				<label htmlFor="speed_recovery">Speed recovery</label>
				<NumericField
					id="speed_recovery"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={speed.recovery}
					/>
			</div>
			<div>
				<label htmlFor="speed_recoveryWeight">Speed recovery</label>
				<NumericField
					id="speed_recoveryWeight"
					onUpdateValue={onUpdateValue}
					value={speed.recoveryWeight}
					/>
			</div>
			<div>
				<label htmlFor="speed_lowerLimit">Speed lower limit</label>
				<NumericField
					id="speed_lowerLimit"
					decimalScale={0}
					onUpdateValue={onUpdateValue}
					value={speed.lowerLimit}
					/>
			</div>
			<div>
				<label htmlFor="speed_lowerLimitWeight">Speed lower limit</label>
				<NumericField
					id="speed_lowerLimitWeight"
					onUpdateValue={onUpdateValue}
					value={speed.lowerLimitWeight}
					/>
			</div>
		</FieldsContainer>
	);
}

const mapStateToProps = state => {
	return {
		speed: state.formData.speed
	};
}

const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SpeedTabFields)
