import axios from 'axios';
import { API_URL } from '../store/configureStore.js';

function uploadData(zipFile, parameters)
{
	const formData = new FormData();
	formData.append('zip_file', zipFile);
	formData.append('parameters', JSON.stringify(parameters));

	const requestOptions = {
		method: 'POST',
		data: formData,
	};

	return axios(`${API_URL}/trimp/zip`, requestOptions)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			return {
				succes: false,
				error: error.getMessage()
			};
		});
}

export default {
	uploadData
};
