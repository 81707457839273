import formDataConstants from '../constants/formDataConstants.js';

const INITIAL_STATE = {
	uploadFile: null,
	heartRate: {
		max: 200,
		maxWeight: 3,
		anaerobic: 180,
		anaerobicWeight: 1,
		aerobic: 150,
		aerobicWeight: 0.5,
		recovery: 120,
		recoveryWeight: 0.3,
		lowerLimit: 105,
		lowerLimitWeight: 0,
	},
	power: {
		maxWeight: 7,
		vo2Max: 250,
		vo2MaxWeight: 3,
		anaerobic: 180,
		anaerobicWeight: 1,
		aerobic: 150,
		aerobicWeight: 0.5,
		recovery: 120,
		recoveryWeight: 0.3,
		lowerLimit: 105,
		lowerLimitWeight: 0,
	},
	speed: {
		maxWeight: 7,
		vo2Max: 14,
		vo2MaxWeight: 3,
		anaerobic: 12,
		anaerobicWeight: 1,
		aerobic: 10,
		aerobicWeight: 0.5,
		recovery: 9,
		recoveryWeight: 0.3,
		lowerLimit: 7,
		lowerLimitWeight: 0,
	}
};

export default function (state = INITIAL_STATE, action)
{
	switch (action.type)
	{
		case formDataConstants.SET_UPLOAD_FILE:
		{
			return {
				...state,
				uploadFile: action.file,
			};
		}
		case formDataConstants.RESET_UPLOAD_FILE:
		{
			return {
				...state,
				uploadFile: null,
			};
		}
		case formDataConstants.SET_HEARTRATE_VALUE:
		{
			return {
				...state,
				heartRate: {
					...state.heartRate,
					[action.property]: action.value
				},
			};
		}
		case formDataConstants.SET_POWER_VALUE:
		{
			return {
				...state,
				power: {
					...state.power,
					[action.property]: action.value
				},
			};
		}
		case formDataConstants.SET_SPEED_VALUE:
		{
			return {
				...state,
				speed: {
					...state.speed,
					[action.property]: action.value
				},
			};
		}
		default:
		{
			return state;
		}
	}
}
