import React, { useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone'

import formDataActions from '../actions/formDataActions.js';

const Div = styled.div`
	background: #fff;
	border: 1px solid #f1f1f1;
	border-radius: 2px;
	padding: 15px;
	margin-bottom: 15px;
`

const Label = styled.label`
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
`

function UploadField(props)
{
	const onDrop = useCallback(acceptedFiles => {
		props.dispatch(formDataActions.setUploadFile(acceptedFiles[0]));
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
		maxSize: 8 * 1000 * 1000,
		onDrop,
	});

	const fileTitle = (!!props.uploadFile) ? props.uploadFile.name : 'Select zip-file';

	return (
		<Div {...getRootProps()}>
			<Label>{fileTitle}</Label>
			<input {...getInputProps()} />
		</Div>
	);
}

const mapStateToProps = state => {
	return {
		uploadFile: state.formData.uploadFile
	};
}

const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UploadField)
