import formUploadConstants from '../constants/formUploadConstants.js';

const INITIAL_STATE = {
	result: null,
	loading: false,
	loaded: null
};

export default function (state = INITIAL_STATE, action)
{
	switch (action.type)
	{
		case formUploadConstants.RESET_SUBMIT_FORM:
		{
			return {
				...INITIAL_STATE,
			};
		}
		case formUploadConstants.SUBMIT_FORM:
		{
			return {
				...state,
				loading: true,
			};
		}
		case formUploadConstants.SUBMIT_FORM_SUCCESS:
		{
			return {
				...state,
				loading: false,
				loaded: new Date(),
				result: action.result
			};
		}
		case formUploadConstants.SUBMIT_FORM_FAILURE:
		{
			return {
				...state,
				loading: false,
				loaded: new Date(),
				result: null
			};
		}
		default:
		{
			return state;
		}
	}
}
