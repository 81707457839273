import React from 'react';
import styled from 'styled-components';

const StyledFieldsContainer = styled.div`
	*zoom: 1

	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}

	> div {
		float: left;
		width: 50%;
		box-sizing: border-box;
		margin-bottom: 15px;

		&:nth-child(2n+1) {
			clear: left;
			padding-right: 10px;
		}
		&:nth-child(2n+0) {
			padding-left: 10px;
		}
		> label {
			display: block;
			color: #aaa;
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 5px;
		}
		> input {
			display: block;
			font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
			font-size: 14px;
			width: 100%;
			box-sizing: border-box;
			border-radius: 2px 2px 0 0;
			border: 1px solid #eee;
			padding: 10px;

			&:active,
			&:focus {
				outline: none;
				border: 1px solid #A4D55C;
			}
		}
	}
`;

export default function FieldsContainer(props)
{
	return (
		<StyledFieldsContainer>
			{props.children}
		</StyledFieldsContainer>
	);
}
